import { memberService, surveyService, adminService, masterService, publicService } from '../services';

const apiModule = {
  namespaced: true,
  state: {
    memberService: memberService,
    surveyService: surveyService,
    adminService: adminService,
    masterService: masterService,
    publicService: publicService
  },
  actions: {
    async apiCall ({ commit }, data) {
      const result = await this.state.api[data.svName][data.fnName](data.param);
      // console.log(result);
      return result;
    }
  }
};

export default apiModule;
