import HttpRequest from './core/http_request';

class PublicProvider extends HttpRequest {
  getMercuryRpt (data) {
    return this.request('get', '/public/surveyMercury/report', data);
  }

  getCropdamageRpt (data) {
    return this.request('get', '/public/surveycorpDamage/report', data);
  }

  getPhysicalHealthRpt (data) {
    return this.request('get', '/public/surveyPhysicalHealth/report', data);
  }

  getSPST20Rpt (data) {
    return this.request('get', '/public/surveySPST20/report', data);
  }

  getOrchardRpt (data) {
    return this.request('get', '/public/surveyOrchard/report', data);
  }

  getAgriculturalRpt (data) {
    return this.request('get', '/public/surveyAgriculturalProduct/report', data);
  }

  getBioBlitzRpt (data) {
    return this.request('get', '/public/surveyBioBlitz/report', data);
  }

  getCitizenPost (data) {
    return this.request('get', '/public/citizenpost', data);
  }

  getTraningVdo (data) {
    return this.request('get', '/public/trainingvdo', data);
  }
}

export default PublicProvider;
